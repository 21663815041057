<template>
  <div class="align-items-center">
    <button class="btn btn-secondary btn-sm" v-on:click="print()">
      <BaseIcon name="printer" />
    </button>
  </div>
</template>
<script>
import BaseIcon from "@/components/icons/BaseIcon";

export default {
  name: "TotalIngredients",
  components: { BaseIcon },
  props: {
    id: Number,
    row: Object,
    modelValue: Array,
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("update", this.value);
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  methods: {
    showModal() {
      this.$refs.modal.showModal();
    },
    print() {
      window.apps.callEvent("print:ingredients", this.id);
    },
  },
  emits: ["update:modelValue", "update"],
};
</script>
